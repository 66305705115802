/* src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


/* Your custom styles go here */
/*--- flone preloader ---*/


.dark {
  background-color: #1a1b1f !important;
}

.flone-preloader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f7f7;
    z-index: 999999;
  }
  
  .flone-preloader {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0;
    text-align: left;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
  }
  
  .flone-preloader span {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #4494AC;
    -webkit-animation: preloader 1.3s linear infinite;
    animation: preloader 1.3s linear infinite;
  }
  
  .flone-preloader span:last-child {
    animation-delay: -0.8s;
    -webkit-animation-delay: -0.8s;
  }
  
  @keyframes preloader {
    0% {
      transform: scale(0, 0);
      opacity: 0.5;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes preloader {
    0% {
      -webkit-transform: scale(0, 0);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--- end of flone preloader ---*/
  .Dropdown-control{
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
    font-size: 0.875rem!important;
    line-height: 1.25rem!important;
    --tw-bg-opacity: 1!important;
    background-color: #fff!important;
    border-radius: 0.5rem!important;
    border-width: 1px!important;
    cursor: pointer !important;
    color: black !important;
    font-weight: 500 !important;
  }
  .Dropdown-arrow {
    border-color: black transparent transparent!important;
    top: 23px!important;
    right: 15px !important;
}

body{
  background-color: #f5f5fa!important;
}
.barCompleted {
  background-color: #81DE4A!important;
}

.container {
  background-color:#F5F5FA!important
}