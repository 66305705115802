/* src/App.css */
@import './index.css';
@import'./styles/_mixins';

/* width */
::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 10px;
    z-index: 1000;
}

/* Track */
::-webkit-scrollbar-track {
    background: #0f1011;
    margin: 0 5px !important;
    padding: 50px 0 !important;
    border: 1px solid #cfdbd524;
    border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #64c6a2;
    border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #609682;
    cursor: pointer;
}

input[type="color"] {
    -webkit-appearance: none;
    border: none;
    padding: 5px;
    width: 100%;
    height: 42px !important;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color"]::-webkit-color-swatch {
    border: solid 2px #cfdbd577;
    border-radius: 5px;
}

.scrolling::-webkit-scrollbar {
    display: block !important;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        height: 0;
        box-shadow: 0 0 0 #ffffff4a;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        border: none;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, .5) !important;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(143, 143, 143, 0.5) !important;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #2869f6;
        cursor: grab;
    }

    ::-webkit-scrollbar-thumb:active {
        background-color: #5489fc;
        cursor: grabbing;
    }

    ::-webkit-slider-runnable-track {
        width: 100%;
        height: 8px;
        cursor: pointer;
        background: #d7dcdf;
        border-radius: 25px;
        border: 1px solid #fff !important;
    }
}

body {
    overflow: hidden;
}

.sidebar {
    width: 100%;
    max-width: 250px;

    @include tablet {
        max-width: 100%;
        display: none;
    }
}

.content {
    width: calc(100% - 250px);
    height: 100vh;
    float: right;

    @include tablet {
        margin-top: 90px !important;
        width: 100%;
        display: block;
    }

    @include mobile {
        margin-top: 90px !important;
        width: 100%;
    }
}

.ProseMirror {
    padding-inline: 4rem;

    >*+* {
        margin-top: 0.75em;
    }

    [data-id] {
        border: 3px solid #ffffffaf;
        border-radius: 0.5rem;
        margin: 1rem 0;
        position: relative;
        margin-top: 1.5rem;
        padding: 2rem 1rem 1rem;

        &::before {
            content: attr(data-id);
            background-color: #ffffffaf;
            font-size: 0.6rem;
            letter-spacing: 1px;
            font-weight: bold;
            text-transform: uppercase;
            color: #ffffffba;
            position: absolute;
            top: 0;
            padding: 0.25rem 0.75rem;
            border-radius: 0 0 0.5rem 0.5rem;
        }
    }
}

.drag-handle {
    align-items: center;
    background: #0000008c;
    border-radius: .25rem;
    border: 1px solid #ffffffaf;
    cursor: grab;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    width: 1.5rem;

    svg {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.editor {
    height: 50%;
    overflow: hidden;
    overflow-y: scroll;
}

.tiptap {
    border-radius: 5px;
    padding: 10px 0;
    margin-bottom: 10px;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    //background: #000;
    //color: #000;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    outline: none;
    cursor: text;
    position: relative;
    transition: border-color 0.2s ease-in-out;

    :first-child {
        margin-top: 0;
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin-top: 2.5rem;
        text-wrap: pretty;
    }

    h1,
    h2 {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 1rem;
    }

    p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    ol {
        list-style-type: decimal; // Change to your preferred list style type
        padding-left: 20px; // Adjust the left padding to your preference
        margin: 0 0 1em 0; // Adjust margin as needed

        li {

            // Reset counter for nested lists
            ol {
                counter-reset: item;
                list-style-type: lower-alpha; // Change to your preferred list style type for nested lists
            }
        }
    }

    ul {
        list-style-type: disc; // Change to your preferred list style type
        padding-left: 20px; // Adjust the left padding to your preference
        margin: 0 0 1em 0; // Adjust margin as needed

        li {
            position: relative; // Required for nested list styling

            // Styling for nested unordered lists
            ul {
                list-style-type: circle; // Change to your preferred nested list style type
                padding-left: 20px; // Adjust padding for nested lists
                margin: 0; // Adjust margin for nested lists

                li {
                    list-style-type: square; // Change to your preferred nested list item style
                    margin-bottom: 0.5em; // Adjust spacing for nested list items
                }
            }
        }
    }

    ul[data-type="taskList"] {
        list-style: inherit;
        padding: 0;

        p {
            margin: 0;
        }

        li {
            display: flex;


            >label {
                flex: 0 0 auto;
                margin-right: 0.5rem;
                user-select: none;

                input[type="checkbox"] {
                    width: 15px;
                    height: 15px;
                }
            }

            >div {
                flex: 1 1 auto;
            }

            ul li,
            ol li {
                display: list-item;
            }

            ul[data-type="taskList"]>li {
                display: flex;
            }
        }
    }
}

/* Your App component styles go here */
.bg_hero {
    background-image: url('../src/assets/Sign-In.jpg');
    background-size: cover;
    background-position: center;
}

.css-8j6yp9-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0 !important;
}

.orline {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
}

.orline:before,
h1:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #CFDBD5;
    margin: auto;
}

.orline:after,
h1:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #CFDBD5;
    margin: auto;
}

.orline:before {
    margin-right: 10px
}

.orline:after {
    margin-left: 10px
}

.bg {
    background-image: url(../src/assets/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
}

// remove svg from checkbox
.de_checkbox {

    // if active class is present
    &.Mui-checked {
        span {
            padding: 5px !important;
            background: #4e84f1;
            border: solid 5px #CFDBD5;
        }

        svg {
            opacity: 1;
        }
    }

    span {
        background: #F5F5FA;
        //border: solid 1px #CFDBD5;
    }

    svg {
        opacity: 1;
    }
}

button {
    &:disabled {
        opacity: 0.2;
    }
}