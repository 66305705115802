.moveButton {
  display: flex;
}

.assign {
  .user {
    &:hover {
      color: #fff;
      cursor: pointer;

      p {
        &:before {
          display: block;
          margin: auto;
          content: '';
          background-image: url("../../assets/user-remove.svg");
          background-repeat: no-repeat;
          background-position: center center;
          width: 30px;
          height: 30px;
          opacity: 0.5;
        }
      }
    }
  }
}

.writing-mode-vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  // Style for the task count
  div {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
}