:root {
  --wallet-button: #151331;
}

/* Colors */
$primary: #9b83f9;
$secondary: #31a2fa;
$disabledColor: #185787;
$hoverColor: #1c99f8;

$textColor: #ffffff;

$blue: #31a2fa;

$mainbg: #0c0d0f !default;
$secondarybg: #0c0d0f !default;
$hoverbg: #282a31 !default;

$input-color: rgba(255, 255, 255, 0.05);

$mobileMenuHight: 60px;

$linearbg: linear-gradient(0deg, #ffffff, #ffffff),
  linear-gradient(113.75deg, $primary 53.46%, $secondary 97.26%) !default;

$border-color: #22213d;

$darkblue: #1b1b1b !default;
$airdrop: #1a314c !default;

$gray: #cccccc !default;
$darkgray: #687599 !default;
$brown: #a0a0a0 !default;
$darkbrown: rgba(69, 104, 126, 0.65) !default;
$light: #f0f0f0 !default;
$battleship: #b0bae2 !default;
$slate: #47d7e2 !default;

$white: white !default;
$blue: #1eb2ff !default;
$aqua: #47d7e2 !default;
$red: #ef4f5a !default;
$orange: #dd794a !default;
$active: rgb(125, 46, 161) !default;

$text: $gray;
$button: white !default;
//$header: $darkblue !default;
$header: white !default;
$saturated-mainColor: saturate(darken($darkblue, 2%), 6%);

$colors: (
  bg: $mainbg,
  darkblue: $darkblue,
  battleship: $battleship,
  slate: $slate,
  blue: $blue,
  aqua: $aqua,
  red: $red,
  orange: $orange,
);

$hr-shadow: fade-out(black, 0.8);
$hr: fade-out(white, 0.92);
$border: 1px solid fade-out(rgba(255, 255, 255, 0.1), 0.6);
$border-radius: 10px;
$border-color: rgba(255, 255, 255, 0.061);

/* Fonts */
$font-size: 14px;
$font-family: "Lexend";

/* Sizes */
$max-width: 1200px !default;
$breakpoint: 992px;
$gutter: 30px;
$line-height: 1.5;
$nav-height: 70px;
$side-nav-height: 15%;
$footer-height: 50px;
$footer-height-mobile: 100px;

$card-padding-horizontal: 30px;
$card-padding-header: 20px $card-padding-horizontal;
$card-padding-main: $card-padding-header;

/* Transitions */
$transition-duration: 0.2s;

/* Z-indexes */
$zindex-sticky: 1020;

$feed-width: 600px;
