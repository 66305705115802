.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.modal {
  width: 100%;
  max-width: 700px;
  min-height: 350px;
  padding: 16px;
  border-radius: 8px;

  //background-color: #FFF;
  z-index: 2001;

  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    padding: 5px;
    width: 100%;
    height: 42px !important;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
  }
}